import React from "react";
import * as PropTypes from "prop-types";
import {SubCategory} from "./subCategory";
import {t} from "i18next";
import "./MainCategory.scss";

SubCategory.propTypes = {data: PropTypes.any};

export class MainCategory extends React.Component {
    render() {
        return <div className={"main-category"}>
            <h1><a id={this.props.data.name}>{t(this.props.data.name, {ns: "menu"})}</a></h1>
            {this.props.data.categories.map(x => <SubCategory key={x.name} data={x} />)}
        </div>;
    }
}

import './App.scss';
import i18next, {t} from "i18next";
import {Link} from "react-router-dom";
import {Component} from "react";
import {smoothScrollTop} from "./utils";
import logo from "./assets/logo_colored.svg";

class App extends Component {
    componentDidMount() {
        smoothScrollTop();
    }

    render() {
        const logoAspect = 5000/3661;
        return (
            <div className="App">
                <div>
                    <img src={logo} width={500*logoAspect+"px"} height={"500px"} />
                    <Link to={`/${i18next.language}/menu`}>{t("menu")}</Link>
                </div>
            </div>
        );
    }
}

export default App;

import React from 'react';
import {t} from "i18next";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Events.scss";

const Events = () => {
    return (
        <div className={"events"}>
            <div>
                <h1>{t("events")}</h1>
                <p>{t("event.description")}</p>
                <a href={"https://www.facebook.com/Ristorante-Pizzeria-Caffe-Litaliano-Sopron-111278428156641/events"}>
                    <FontAwesomeIcon icon={faFacebook} />
                    {t("event.btn")}
                </a>
            </div>
        </div>
    );
};

export default Events;

import React from "react";
import {t} from "i18next";
import "./About.scss";
import aboutImg from "./assets/about.webp";

export class About extends React.Component {
    render() {
        return <div className={"about"}>
            <img src={aboutImg} height={"420"} width={"691"} />
            <div>
                <div>
                    <h1>{t("about")}</h1>
                    <div>
                        {t("about_text").split("|").map((x, i) => <p key={i}>{x}</p>)}
                    </div>
                </div>
            </div>
        </div>;
    }
}

import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import i18next, {t} from "i18next";
import "./Navbar.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faBars} from "@fortawesome/free-solid-svg-icons";
import {NavHashLink} from "react-router-hash-link";

export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.state = {visible: false};
    }

    toggleMenu() {
        this.setState({visible: !this.state.visible})
    }

    hideMenu() {
        this.setState({visible: false})
    }

    activeClass({isActive}) {
        return isActive ? "active-link" : "";
    }

    render() {
        return <div className={"navbar"} style={{"--bgo": "0"}}>
            <FontAwesomeIcon onClick={this.toggleMenu} icon={faBars} className={"menu-toggle"}/>
            <ul className={this.state.visible ? "show" : ""}>
                <li><NavLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/`} onClick={this.hideMenu}>{t("home")}</NavLink></li>
                <li><NavLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/about`} onClick={this.hideMenu}>{t("about")}</NavLink></li>
                <li><NavLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/events`} onClick={this.hideMenu}>{t("events")}</NavLink></li>
                <li><NavHashLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/menu#food`} smooth onClick={this.hideMenu}>{t("food")}</NavHashLink></li>
                <li><NavHashLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/menu#drink`} smooth onClick={this.hideMenu}> {t("drink")}</NavHashLink></li>
                {/*<li><NavHashLink className={this.activeClass} hrefLang={i18next.language} to={`/${i18next.language}/gallery`} smooth onClick={this.hideMenu}> {t("gallery")}</NavHashLink></li>*/}
            </ul>
            <a href={"tel:+3699369991"}><FontAwesomeIcon icon={faPhone} /><span>+36&nbsp;99&nbsp;369&nbsp;991</span></a>
            <span />
        </div>;
    }
}

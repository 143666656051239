import React from "react";
import {t} from "i18next";
import m from "./assets/menu.json";
import "./Menu.scss";
import {MainCategory} from "./mainCategory";

MainCategory.propTypes = {};

export class Menu extends React.Component {
    render() {
        return <div className={"menu"}>
            <div>
                {m.map(x => <MainCategory key={x.name} data={x} />)}
                <div className={"allergens"}>
                    <h3>{t("allergens.table", {ns: "menu"})}</h3>
                    {
                        ["A","B","C","D","E","F","G","H","I","J","K","L","M","O"].map(x => <span key={x}><b>{x}: </b>{t("allergens."+x, {ns: "menu"})}  </span>)
                    }
                </div>
            </div>
        </div>;
    }
}

import React from "react";
import {t} from "i18next";
import "./SubCategory.scss";

export class SubCategory extends React.Component {
    render() {
        let _c = (a, b) => a + "." + b;
        return <div className={"sub-category"}>
            <h2>{t(this.props.data.name, {ns: "menu"})}</h2>
            <table>
                <tbody>
                {this.props.data.items.map(x =>
                    <tr key={x.name}>
                    <td>
                        <p>
                            {t(_c(x.id, "name"), {ns: "menu", lng: "it"})}
                            {x.allergens ?
                                <span className={"allergen-list"}>
                                ({(x.allergens ?? []).map(a => <span key={a}>{a}</span>)})
                            </span> : null
                            }
                        </p>
                        {typeof x.price === "number" ?
                            <p className={"multi-desc"}>{t(_c(x.id, "desc"), {ns: "menu", defaultValue: ""})}</p> :
                            x.price.map((v,i) => <p key={i}>{t(_c(_c(x.id, "desc"), i), {ns: "menu"})} <b>{v}&nbsp;HUF</b></p>)
                        }
                    </td>
                        {typeof x.price === "number" ?
                            <td>{x.price}&nbsp;HUF</td> : null
                        }
                    </tr>
                )}
                </tbody>
            </table>
        </div>;
    }
}

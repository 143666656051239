import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {initReactI18next} from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import i18next, {t} from "i18next";
import {Navbar} from "./Navbar";
import {Footer} from "./Footer";
import {About} from "./about";
import {Menu} from "./menu";
import Events from "./Events";
import Gallery from './Gallery';
import NotFound from './NotFound';
import {updateActualWidth} from "./utils";
import {Helmet} from "react-helmet";

i18next
    .use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ["path", "navigator"],
            lookupFromPathIndex: 0
        },
        preload: ["it"],
        supportedLngs: ["hu", "it"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        },
        react: {
            bindI18n: 'loaded languageChanged',
            bindI18nStore: 'added',
            useSuspense: true,
        },
        ns: ["default", "menu", "gallery"],
        fallbackLng: "hu"
    }).then(x => {
        if (!window.location.pathname.startsWith(`/${i18next.language}`)) {
            window.history.pushState("", "",`/${i18next.language}${window.location.pathname}`);
        }
        const root = document.getElementById("root");
        const pageRoutes = [
            <Route path="" element={<App />} />,
            <Route path="about" element={<About />} />,
            <Route path="menu" element={<Menu />} />,
            <Route path="events" element={<Events />} />,
            <Route path="gallery" element={<Gallery />} />,
            <Route path="*" element={<NotFound />} />
        ]
        ReactDOM.render(
            <BrowserRouter>
                <Helmet>
                    <html lang={i18next.language} />
                    <meta name={"description"} content={t("site.description")}/>
                    <title>{t("site.title")}</title>
                </Helmet>
                <Navbar />
                <div className={"content"} onresize={updateActualWidth}>
                    <Routes>
                        {i18next.languages.map(lang => <Route key={lang} path={lang}>{pageRoutes}</Route> )}
                        <Route path={"*"} element={<NotFound />} />
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>,
            root
        );
        updateActualWidth();
        window.addEventListener("resize", updateActualWidth);
        root.addEventListener("scroll", () => {
            let y = root.scrollTop;
            const navHeight = 66*2;
            document.querySelector(".navbar").style.setProperty("--bgo", Math.min(1, y / navHeight));
        });
});

import React from 'react';
import {t} from "i18next";

function NotFound() {
    return (
        <div>
            <p>{t('page_not_found')}</p>
        </div>
    );
}

export default NotFound;

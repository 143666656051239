import {Component} from "react";
import i18next, {changeLanguage, t} from "i18next";
import "./Footer.scss";
import {Trans} from "react-i18next";
import {
    faCalendarDay,
    faEnvelope,
    faHashtag,
    faLocationDot,
    faMessage,
    faPhone
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

export class Footer extends Component {
    chLang(lang) {
        const current = i18next.language;
        changeLanguage(lang).then(x => window.location.pathname = window.location.pathname.replace(current, lang))
    }

    render() {
        return <div className={"footer"}>
            <div>
                <div>
                    <div>
                        <FontAwesomeIcon icon={faCalendarDay} />
                        <p>{t("opening_hours")}</p>
                    </div>
                    <div>
                        <table>
                            <tbody>
                            <tr><td colspan="2">{t("kopen")}</td></tr>
                            <tr>
                                <th>{t("day.mon")}</th>
                                <td>{t("closed")}</td>
                            </tr>
                            <tr>
                                <th>{t("day.tue")}</th>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <th>{t("day.wed")}</th>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <th>{t("day.thu")}</th>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <th>{t("day.fri")}</th>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <th>{t("day.sat")}</th>
                                <td>10:00 - 21:00</td>
                            </tr>
                            <tr>
                                <th>{t("day.sun")}</th>
                                <td>10:00 - 18:00</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div>
                        <FontAwesomeIcon icon={faMessage} />
                        <p>{t("contact")}</p>
                    </div>
                    <div>
                        <ul className={"contact"}>
                            <li>
                                <FontAwesomeIcon icon={faLocationDot} />
                                <a>9400 Sopron, Gyár u. 1-3.</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPhone} />
                                <a href="tel:+3699369991">+36 99 369 991</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faPhone} />
                                <a href="tel:+36303189894">+36 30 318 9894</a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} />
                                <a href="mailto:info@sopronitaliano.hu">info@sopronitaliano.hu</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <FontAwesomeIcon icon={faHashtag} />
                        <p>{t("social")}</p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faFacebook} />
                                <a href="https://www.facebook.com/Ristorante-Pizzeria-Caffe-Litaliano-Sopron-111278428156641">Facebook</a>
                            </li>
                            {/*<li>*/}
                            {/*    <FontAwesomeIcon icon={faInstagram} />*/}
                            {/*    <a href="#">Instagram</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <p>{t("lang")}</p>
                    </div>
                    <div className={"lang-switcher"}>
                        <p onClick={() => this.chLang("hu")}>{t('lngs.hungarian')}</p>
                        {/*<p onClick={() => this.chLang("de")}>{t('lngs.german')}</p>*/}
                    </div>
                </div>
            </div>
            <div>
                &#169; {t("copyright")}<br/>
                <Trans i18nKey={"designed_by"} components={[(<a href={"https://noelnemeth.com"}/>)]} />
            </div>
        </div>;
    }
}

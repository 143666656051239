import React from 'react';
import images from './assets/gallery.json';
import {t} from "i18next";
import './Gallery.scss';
import {smoothScrollTop} from "./utils";

class Gallery extends React.Component {
    componentDidMount() {
        smoothScrollTop();
    }

    render() {
        return (
            <div className={"gallery"}>
                {images.map(img => <div key={img.url}>
                    <img alt="" src={img.url} width={"600"} height={"600"}/>
                    <p>{t(img.key, {ns: "gallery"})}</p>
                </div>)}
            </div>
        );
    }
}

export default Gallery;
